<template>
  <ion-page>
    <Header title="Favorites"/>
    <ion-content :fullscreen="true">

      <ion-grid class="thumb-article-section">
        <ArticleXsThumbnailCard v-for="(article, index) in articles"
                                :key="index"
                                :title="article.title"
                                :image="article.image"
                                :category="article.category"
                                :read-time="article.readTime"
                                :viewed="article.viewed"
                                :display-favorite-icon="true"
        />
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import Header from "@/layouts/Header.vue";
import ArticleXsThumbnailCard from "@/components/cards/ArticleXsThumbnailCard.vue"
import {IonContent, IonGrid, IonPage} from '@ionic/vue';
import store from '@/store';
import {ThumbnailArticleInterface} from "@/interfaces/Article";

export default {
  name: 'search',
  components: {
    ArticleXsThumbnailCard,
    Header,
    IonContent,
    IonPage,
    IonGrid,
  },
  setup: () => {
    const articles: Array<ThumbnailArticleInterface> = store.state.articles;
    return {
      articles
    }
  }
}
</script>
