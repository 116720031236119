
import Header from "@/layouts/Header.vue";
import ArticleXsThumbnailCard from "@/components/cards/ArticleXsThumbnailCard.vue"
import {IonContent, IonGrid, IonPage} from '@ionic/vue';
import store from '@/store';
import {ThumbnailArticleInterface} from "@/interfaces/Article";

export default {
  name: 'search',
  components: {
    ArticleXsThumbnailCard,
    Header,
    IonContent,
    IonPage,
    IonGrid,
  },
  setup: () => {
    const articles: Array<ThumbnailArticleInterface> = store.state.articles;
    return {
      articles
    }
  }
}
